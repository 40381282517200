import React from "react"
import TopArea from "./components/TopArea"
import TopImage from "./assets/img/aboutTop.jpg"
import boardList from "./data/boardList"
import BoardCard from "./components/BoardCard"

const About = () => {
    return(
        <div className="About">
            <TopArea 
                image={TopImage}
                alt=''
                title="About"
            />
            <h2 className="SectionTitle">History</h2>
            <p className="paragraph">The Shattered Ceiling Scholarship Award was created by Angela Freeman. Each December since 2006, Angela honors her relationships with her closest friends through an annual ladies gathering. In 2016 she decided that every young lady should know how it feels to be surrounded by positive, and inspirational women. Women who truly live, laugh and love. Most of all, these women are trailblazers who want to make a difference.  With the help of some of the strongest, most compassionate and kick-ass ladies, Angela has collected funds to support one (1) like-minded high school senior that is determined to follow her dreams and shatter that glass ceiling!</p>
            <h2 className="SectionTitle">Meet the Board</h2>
            <div className="BoardContainer">
                {boardList.map((member, index) => 
                    <BoardCard
                        key={index} 
                        name={member.name}
                        title={member.title}
                        image={member.image}
                    />
                )}
            </div>
            <h2 className="SectionTitle">From an Alumni...</h2>
            <p className="paragraph">I want to say thank you again, to you and to everyone behind this scholarship. The monetary value is helpful, yes, but the meaning behind it so much more important. Empowering women to do great things. The award ceremony allowed me to meet so many incredible women, such as yourself, as well as the other recipients. I'm so grateful for the opportunity to be apart of such an amazing group of people. It helped me realize that I am not alone in this journey, and that will continue to give me confidence as I dive into this career (which tends to be male dominated).</p>
            <h4>- Anna Merrill</h4>
        </div>
    )
}

export default About;