import React from "react"
import TopArea from "./components/TopArea"
import Carousel from "react-bootstrap/Carousel"
import AlumniCard from "./components/AlumniCard"
import alumniList from "./data/alumniList"

// Image Imports
import TopImage from "./assets/img/homeTop.jpg"
import AlumniImage from "./assets/img/alumni.jpg"

const Home = () => {
    return(
        <div className="Home">
            <TopArea image={TopImage} alt="Photo by Joel Muniz" title='' />
            <p className="paragraph">The Shattered Ceiling Scholarship is an Award for young women that live big, and dream bigger. Every year, one young woman is chosen as the recipient in the hopes of empowering them to become their true idea of success.</p>
            <img style={{display: 'block'}} className="SectionImage" src={AlumniImage} alt="Two alumni of the Shattered Ceiling Scholarship standing with Angela Freeman." />
            <h8 className="PhotoCaption">From left to right; Lizzy Herrick (Alumni), Angela Freeman (Founder) Imani Wolery (Alumni)</h8>
            <h2 className="SectionTitle">Alumni</h2>
            <div className="CardContainer NoDisplayMobile">
            {alumniList.map((alum, index) =>
                            <AlumniCard 
                                key={index}
                                name={alum.name}
                                image={alum.image}
                                quote={alum.quote}
                                year={alum.year}
                            />
                    )}
            </div>
            <div className="CarouselContainer NoDisplayDesktop">
                <Carousel indicators={false}>
                    {alumniList.map((alum, index) =>
                        <Carousel.Item key={index}>
                            <AlumniCard 
                                name={alum.name}
                                image={alum.image}
                                quote={alum.quote}
                                year={alum.year}
                            />
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>
        </div>
    )
}

export default Home;