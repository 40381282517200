import React from "react"

// Image Imports
// import Quote from "../assets/img/quote.svg"

const AlumniCard = (props) => {
    return(
        <div className="AlumniCard">
            {/* <div className="QuoteBox">
                <img className="QuoteIcon" src={Quote} alt="" />
            </div>
            <div className="AlumniFlair">
                <h3 className="AlumniQuote">{props.quote}</h3>
                <img className="AlumniImage" src={props.image} alt="" />
            </div>
            <div className="AlumniInfo">
                <h3 className="AlumniYear">{props.year} -</h3>
                <h3 className="AlumniName">{props.name}</h3>
            </div> */}
            <img className="AlumniImage" src={props.image} alt="" width="100%" height="45%" />
            <h3 className="AlumniName">{props.name}</h3>
            <div className="QuoteContainer">
                <p className="AlumniQuote">{props.quote}</p>
            </div>
            <h2 className="AlumniYear">{props.year}</h2>
        </div>
    )
}

export default AlumniCard;