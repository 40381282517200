import React from 'react'

const MailchimpSignUp = () => {
    return(
        <div id="mc_embed_signup">
            <form action="https://outlook.us6.list-manage.com/subscribe/post?u=d9827cd721c94a530c83b645a&amp;id=0388039da2" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                    {/* <div className="indicates-required"><span className="asterisk">*</span> indicates required</div> */}
                    <div className="mc-field-group">
                        <label for="mce-EMAIL">Email Address  <span className="asterisk">*</span></label>
                        <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                    </div>
                    <div className="mc-field-group">
                        <label for="mce-FNAME">First Name </label>
                        <input type="text" name="FNAME" className="" id="mce-FNAME" />
                    </div>
                    <div className="mc-field-group">
                        <label for="mce-LNAME">Last Name </label>
                        <input type="text" name="LNAME" className="" id="mce-LNAME" />
                    </div>
                    <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                        <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                    </div>    
                    {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                    <div style={{position: 'absolute'}, {left: '-5000px'}} aria-hidden="true"><input type="text" name="b_d9827cd721c94a530c83b645a_0388039da2" tabindex="-1" value="" style={{display: 'none'}} /></div>
                    <div className="clear"><button type="submit" className="MCButton">Subscribe</button></div>
                </div>
            </form>
        </div>
        
    )
}

export default MailchimpSignUp
