import React from "react"
import TopArea from "./components/TopArea"

import TopImage from "./assets/img/contactTop.jpg"
import Facebook from "./assets/img/facebookLogo.svg"

const Contact = () => {
    return(
        <div className="Contact">
            <TopArea image={TopImage} title="Contact"/>
            <div className="ContactInfo">
                <h2 className="SectionTitle">Have a Question?</h2>
                <p className="paragraph">If you have any questions or want to get in touch, feel free to send an email to <a href="mailto:angelareafreeman@gmail.com">angelareafreeman@gmail.com</a></p>
            </div>
            <div className="SocialMedia">
                <a href="https://www.facebook.com/groups/351943465177312"><img className="SocIcon" src={Facebook} /></a>
            </div>
        </div>
    )
}

export default Contact;