import React, {Component} from "react"
import './styles/styles.css';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./Home";
import About from "./About";
import Apply from "./ApplyClass";
import ApplySuccess from "./ApplySuccess";
import ApplyError from "./ApplyError";
import Contact from "./Contact";
import MobileBottom from './components/MobileBottom';
import ScrollToTop from "./ScrollToTop";
import { Modal } from "react-bootstrap";
import MailchimpSignUp from "./components/MailchimpSignUp";
import Hamburger from "./assets/img/Hamburger_icon.png";
import Close from "./assets/img/cross-small-01-512.webp";


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDonate: false,
      showEmail: false,
      isToggled: 'untoggled',
      img: Hamburger
    };
  }

  expandMenu = () => {
    if (this.state.isToggled === "untoggled") {
        this.setState({isToggled: 'toggled'});
        this.setState({img: Close});
    }
    else if (this.state.isToggled === "toggled") {
        this.setState({isToggled: 'untoggled'});
        this.setState({img: Hamburger});
    }
    
  }

  handleLinkClick = () => {
    this.setState({isToggled: 'untoggled'});
    this.setState({img: Hamburger});
  }

  handleCloseDonate = () => {
    this.setState({showDonate: false})
  }

  handleShowDonate = () => {
    this.setState({showDonate: true})
  }

  handleCloseEmail = () => {
    this.setState({showEmail: false})
  }

  handleShowEmail = () => {
    this.setState({showEmail: true})
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className="App">
            <Modal className="DonateModal" show={this.state.showDonate} onHide={this.handleCloseDonate}>
              <Modal.Header closeButton>
                <Modal.Title>Make a Donation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <iframe src="https://givebutter.com/embed/c/aO9sa1" width="100%" height="615px" style={{maxWidth: '601px'}} name="givebutter" frameborder="0" scrolling="yes" seamless allowpaymentrequest></iframe>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
            <Modal className="DonateModal" show={this.state.showEmail} onHide={this.handleCloseEmail}>
              <Modal.Header closeButton>
                <Modal.Title>Subscribe to Our Email List</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <MailchimpSignUp />
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
            <Navbar isToggled={this.state.isToggled} img={this.state.img} expandMenu={this.expandMenu} handleLinkClick={this.handleLinkClick} handleShowDonate={this.handleShowDonate} handleShowEmail={this.handleShowEmail} />
            <div className="Content">
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/about">
                    <About />
                  </Route>
                  <Route path="/apply" component={Apply} />
                  <Route path="/success">
                    <ApplySuccess />
                  </Route>
                  <Route path="/applicationerror">
                    <ApplyError />
                  </Route>
                  <Route path="/contact">
                    <Contact />
                  </Route>
                </Switch>
            </div>
            <MobileBottom handleLinkClick={this.handleLinkClick} handleShow={this.handleShowDonate}  />
        </div>
      </Router>
    );
  }
}

export default App;
