import React from "react"

const TopArea = (props) => {
    return(
        <div className="TopArea">
            <img className="TopImage" src={props.image} alt={props.alt} />
            <div className="TopCard">
                <h1>{props.title}</h1>
            </div>
        </div>
    )
}

export default TopArea;