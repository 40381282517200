import React from "react"

const BoardCard = (props) => {
    return(
        <div className="BoardCard">
            <img className="BoardImage" src={props.image} alt={props.name} />
            <div className="BoardInfo">
                <h3 className="BoardName">{props.name}</h3>
                <h3 className="BoardTitle">{props.title}</h3>
            </div>
        </div>
    )
}

export default BoardCard;