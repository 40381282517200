import React, {Component} from "react";
import { Link } from "react-router-dom";
import MailchimpSignUp from "./MailchimpSignUp";

// Image Imports
import Hamburger from "../assets/img/Hamburger_icon.png";
import Close from "../assets/img/cross-small-01-512.webp";
import Logo from "../assets/img/SCLogo.svg"


class Navbar extends Component {


    render() {
        return(
            <div>
                <nav className="Navbar">
                        <img className="Logo" src={Logo} alt="Shattered Ceiling Scholarship Award" />
                        <div className="NavContainer">
                            <div className="NavButtonContainer">
                                <Link to="/" className="NavButton">Home</Link>
                                <Link to="/about" className="NavButton">About</Link>
                                <Link to="/apply" className="NavButton">Apply</Link>
                                <Link to="/contact" className="NavButton">Contact</Link>
                            </div>
                            <button onClick={() => this.props.handleShowDonate()} className="NavDonateButton">Donate</button>
                            <p>for tax deductible donations, donate through <a href="http://www.know-agenda.org/fiscal-sponsorship.html">Know Agenda Foundation.</a><br /><span>9% of the donation will go to service fees</span></p>
                            <h3><a href="#" onClick={() => this.props.handleShowEmail()}>Sign up For Email Updates</a></h3>
                        </div>
                    <button onClick={() => this.props.expandMenu()} className="toggleButton NoDisplayDesktop">
                        <img className="menuToggleButton" src={this.props.img} alt="Menu Button" />
                    </button>
                    <div className={"MobileMenu" + ' ' + this.props.isToggled + ' ' + "NoDisplayDesktop"}>
                        <Link className="link" to="/">
                            <div onClick={() => this.props.handleLinkClick()} className="LinkDiv">
                                <h3>Home</h3>
                            </div>
                        </Link>
                        <Link className="link" to="/about">
                            <div onClick={() => this.props.handleLinkClick()} className="LinkDiv">
                                <h3>About</h3>
                            </div>
                        </Link>
                        <Link className="link" to="/apply">
                            <div onClick={() => this.props.handleLinkClick()} className="LinkDiv">
                                <h3>Apply</h3>
                            </div>
                        </Link>
                        <Link className="link" to="/contact">
                            <div onClick={() => this.props.handleLinkClick()} className="LinkDiv">
                                <h3>Contact</h3>
                            </div>
                        </Link>
                        <span className="ButtonContainer">
                            <button onClick={() => this.props.handleShowDonate()} type="button">Donate</button>
                        </span>
                        <span className="ButtonContainer">
                            <p>for tax deductible donations, donate through <br /> <a href="http://www.know-agenda.org/fiscal-sponsorship.html">Know Agenda Foundation</a> <br /><span>9% of the donation will go to service fees</span></p>
                        </span>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;